/* FillingCircle.css */

.circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fdfaef;
  }
  
  .circle {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #e0e0e0;
  }
  
  .circle-fill {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #56d2a0;
    animation: fillUp 5s linear forwards;
  }
  
  .circle-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    animation: overlayHide 5s forwards;
    transform-origin: top;
  }
  
  .circle-fill-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
  @keyframes fillUp {
    0% {
      height: 0%;
    }
    100% {
      height: 100%;
    }
  }
  
  @keyframes overlayHide {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }
  